import {VisaLists, Passport, Destination} from '../common';
import {UiCompareElement} from './types';
import {areAllSame} from '../utils';

const TF_CODE_PRIORITY: VisaLists.FreedomCode[] = [
  'SELF',
  'VISAFREE',
  'ETA',
  'VISAPRIOR',
  'BANNED',
];

/**
 * Returns a positive number if code1 is more restrictive than code2.
 * Returns a negative number if code2 is more restrictive than code1.
 * Returns 0 if code1 and code2 are the same.
 */
export function compareFreedomCode(
  code1: VisaLists.FreedomCode,
  code2: VisaLists.FreedomCode
): number {
  return TF_CODE_PRIORITY.indexOf(code1) - TF_CODE_PRIORITY.indexOf(code2);
}

export function compareText(passport: Passport.Data, destination: Destination.Data): string {
  if (!passport || !destination) {
    return '';
  }
  if (passport.code === destination.code) {
    return 'Self';
  }
  if (passport.visafreeList.includes(destination.code)) {
    return 'Visa-free';
  }
  if (passport.etaList.includes(destination.code)) {
    return 'E-Visa';
  }
  if (passport.visapriorList.includes(destination.code)) {
    return 'Visa required';
  }
  if (passport.bannedList.includes(destination.code)) {
    return 'Banned';
  }
  return 'Unknown';
}

export function compareClass(passport: Passport.Data, destination: Destination.Data): string {
  if (!passport || !destination) {
    return '';
  }
  if (passport.code === destination.code) {
    return 'bg-purple-600';
  }
  if (passport.visafreeList.includes(destination.code)) {
    return 'bg-green-600 ';
  }
  if (passport.etaList.includes(destination.code)) {
    return 'bg-yellow-600';
  }
  if (passport.visapriorList.includes(destination.code)) {
    return 'bg-red-600';
  }
  if (passport.bannedList.includes(destination.code)) {
    return 'bg-gray-600';
  }
  return '';
}

/*
 * Returns an array telling whether all the freedom codes in compareElements for
 * the given destination are identical.
 *
 * @param compareElements must be *selected* UiCompareElement objects.
 * @return a boolean array the same size as destinations parameter.
 */
export function calculateIdenticalDestinations(
  compareElements: UiCompareElement[],
  destinations: Destination.Data[]
): boolean[] {
  const destinationTravelFreedoms: VisaLists.FreedomCode[][] = [];
  destinations.forEach((destination: Destination.Data) => {
    const travelfreedoms: VisaLists.FreedomCode[] = [];
    compareElements.forEach((compareElement: UiCompareElement) => {
      travelfreedoms.push(
        VisaLists.getFreedomCode(compareElement.selectedDocument, destination.code)
      );
    });
    destinationTravelFreedoms.push(travelfreedoms);
  });
  return destinationTravelFreedoms.map(areAllSame);
}
