import {SingleRanking, RankingTableItem, RankingBuckets} from './types';
import {Passport} from '../common';

export function buildRankingBuckets(
  singleRanking: SingleRanking,
  passports: Passport.Data[]
): RankingTableItem[] {
  const findPassport = (code: string): Passport.Data =>
    passports.find((p) => p.globalCode === code);
  const rankingBuckets: RankingBuckets = {};
  Object.values(singleRanking).forEach((v: number) => {
    rankingBuckets[Math.round(v)] = {documents: [], value: v};
  });
  Object.keys(singleRanking).forEach((key) => {
    rankingBuckets[Math.round(singleRanking[key])].documents.push(findPassport(key));
  });
  const rankedPassports: RankingTableItem[] = [];
  Object.keys(rankingBuckets)
    .map((s) => +s)
    .sort((n1, n2) => n2 - n1)
    .forEach((bucketKey: number) => {
      rankedPassports.push({
        documents: rankingBuckets[bucketKey].documents,
        value: +bucketKey,
      });
    });
  return rankedPassports;
}

export function buildOrderedRankingBuckets(
  singleRanking: SingleRanking,
  passports: Passport.Data[]
): RankingTableItem[] {
  return buildRankingBuckets(singleRanking, passports).reverse();
}
