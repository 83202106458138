/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import {graphql, Link, useStaticQuery} from 'gatsby';
import React, {useEffect, useState} from 'react';
import ReactTooltip from 'react-tooltip';

import {getFlagEmoji, PassportType, WebPassport} from '@travelfreedom/shared';

import {
  buildMapDataFromCompareMap,
  Layout,
  MAP_COLORS,
  PassportImage,
  SelectCountry,
  SelectPassportType,
  SEO,
  WorldMap,
} from '../components';
import {Destination, Passport, VisaLists} from '../library/common';
import {
  calculateIdenticalDestinations,
  compareClass,
  CompareElement,
  compareFreedomCode,
  CompareMapObject,
  compareText,
  UiCompareElement,
} from '../library/evaluation';

function nameLocaleComparator(n1: VisaLists.Data, n2: VisaLists.Data): number {
  return n1.name.localeCompare(n2.name);
}

function useUiCompareElements(
  compareElements: CompareElement[],
  allPassports: Passport.Data[]
): UiCompareElement[] {
  return compareElements.map((ce) => {
    const selectedDocument = ce.selectedGlobalCode
      ? allPassports.find((d) => d.globalCode === ce.selectedGlobalCode)
      : null;
    return {
      type: ce.type,
      selectedDocument,
      documentSelection: allPassports.filter((d) => d.type === ce.type),
      hasStats: false,
      visafree: 0,
      eta: 0,
      visaprior: 0,
      banned: 0,
    };
  });
}

function useSelectedUiCompareElements(uiCompareElements: UiCompareElement[]) {
  return uiCompareElements.filter((ce) => !!ce.selectedDocument && ce.selectedDocument.loaded);
}

function useRankingMapObject(
  selectedUiCompareElements: UiCompareElement[],
  mapModeInclusive: boolean
): CompareMapObject {
  const rankingMapObject: CompareMapObject = {};
  selectedUiCompareElements
    .map((ce) => ce.selectedDocument)
    .forEach((passport: Passport.Data) => {
      VisaLists.allCodes(passport).forEach((code) => {
        const existingTf = rankingMapObject[code];
        let currentTf = VisaLists.getFreedomCode(passport, code);
        if (currentTf === 'SELF') {
          currentTf = 'VISAFREE';
        }
        if (
          !existingTf ||
          (mapModeInclusive && compareFreedomCode(currentTf, existingTf) < 0) ||
          (!mapModeInclusive && compareFreedomCode(currentTf, existingTf) > 0)
        ) {
          rankingMapObject[code] = currentTf;
        }
      });
    });
  return rankingMapObject;
}

function useDestinationsToShow(
  selectedUiCompareElements: UiCompareElement[],
  allDestinations: Destination.Data[],
  showAllDestinations: boolean
) {
  const isSame = calculateIdenticalDestinations(selectedUiCompareElements, allDestinations);
  return allDestinations.filter((d, index) => showAllDestinations || !isSame[index]);
}

function useUiCompareElementsWithStats(
  uiCompareElements: UiCompareElement[],
  destinationsToShow: Destination.Data[]
): UiCompareElement[] {
  return uiCompareElements.map((compareElement) => {
    const passport = compareElement.selectedDocument;
    if (!passport) {
      return compareElement;
    }
    return {
      ...compareElement,
      hasStats: true,
      visafree: destinationsToShow.filter((d) => VisaLists.isVisaFree(passport, d.code)).length,
      eta: destinationsToShow.filter((d) => VisaLists.isEta(passport, d.code)).length,
      visaprior: destinationsToShow.filter((d) => VisaLists.isVisaPrior(passport, d.code)).length,
      banned: destinationsToShow.filter((d) => VisaLists.isBanned(passport, d.code)).length,
    };
  });
}

function useShareUrl(selectedUiCompareElements: UiCompareElement[]): string {
  if (selectedUiCompareElements.length <= 0) return '';
  const combinedCodes = selectedUiCompareElements
    .map((ce) => ce.selectedDocument.globalCode.toLowerCase())
    .join('|');
  return `${window.location.origin}/compare/?compare=${combinedCodes}`;
}

export const ComparePage = (): JSX.Element => {
  const {
    allMongodbTravelfreedomDestinations,
    allMongodbTravelfreedomPassports,
    allContentfulPassport,
  } = useStaticQuery(graphql`
    query {
      allMongodbTravelfreedomDestinations {
        nodes {
          code
          name
        }
      }
      allMongodbTravelfreedomPassports(filter: {type: {ne: "NATIONAL_ID"}}) {
        nodes {
          name
          code
          type
          adjective
        }
      }
      allContentfulPassport(filter: {type: {ne: "NATIONAL_ID"}}) {
        nodes {
          globalCode
          images {
            localFile {
              childImageSharp {
                smallImage: gatsbyImageData(width: 110, height: 150)
                largeImage: gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  // "True" states
  const [allDestinations] = useState<Destination.Data[]>(
    allMongodbTravelfreedomDestinations.nodes
      .map(({code, name}) => Destination.from(code, name))
      .sort(nameLocaleComparator)
  );
  const [allPassports, setAllPassports] = useState<Passport.Data[]>(
    allMongodbTravelfreedomPassports.nodes
      .map(({code, name, type, adjective}) => Passport.from(code, type, name, adjective))
      .sort(nameLocaleComparator)
  );
  const [compareElements, setCompareElements] = useState<CompareElement[]>([
    {selectedGlobalCode: null, type: 'ORDINARY'},
    {selectedGlobalCode: null, type: 'ORDINARY'},
  ]);
  const [showAllDestinations, setShowAllDestinations] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [mapModeInclusive, setMapModeInclusive] = useState(true);
  const [copyAlertVisible, setCopyAlertVisible] = useState(false);

  // "Derived" states
  const uiCompareElements = useUiCompareElements(compareElements, allPassports);
  const selectedUiCompareElements = useSelectedUiCompareElements(uiCompareElements);
  const rankingMapObject = useRankingMapObject(selectedUiCompareElements, mapModeInclusive);
  const destinationsToShow = useDestinationsToShow(
    selectedUiCompareElements,
    allDestinations,
    showAllDestinations
  );
  const uiCompareElementsWithStats = useUiCompareElementsWithStats(
    uiCompareElements,
    destinationsToShow
  );
  const shareUrl = useShareUrl(selectedUiCompareElements);

  // Images
  const smallImagesMap = new Map();
  const largeImagesMap = new Map();
  allContentfulPassport.nodes.forEach((contentfulNode) => {
    if (!contentfulNode.images) {
      return;
    }
    const {globalCode} = contentfulNode;
    const {smallImage, largeImage} = contentfulNode.images[0].localFile.childImageSharp;
    smallImagesMap.set(globalCode, smallImage);
    largeImagesMap.set(globalCode, largeImage);
  });

  // Field references
  const shareUrlField: React.RefObject<HTMLInputElement> = React.createRef();

  const copyUrl = (inputElement: HTMLInputElement): void => {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    setCopyAlertVisible(true);
    setTimeout(() => {
      setCopyAlertVisible(false);
    }, 1000);
  };

  const addDocument = (): void => {
    setCompareElements([
      ...compareElements,
      {
        selectedGlobalCode: null,
        type: 'ORDINARY',
      },
    ]);
  };

  const updateCompareElement = (compareElement: CompareElement, index: number): void => {
    setCompareElements([
      ...compareElements.slice(0, index),
      compareElement,
      ...compareElements.slice(index + 1),
    ]);
  };

  const updateExtraInfo = (globalCode: string, extra: WebPassport): void => {
    const index = allPassports.findIndex((p) => p.globalCode === globalCode);
    if (index === -1) {
      // eslint-disable-next-line no-console
      console.error(`Global code ${globalCode} not found`);
      return;
    }
    const passport = allPassports[index];
    Passport.loadJson(passport, extra);
    const newAllPassports = [
      ...allPassports.slice(0, index),
      passport,
      ...allPassports.slice(index + 1),
    ];
    setAllPassports(newAllPassports);
  };

  const changeDocument = (index: number, type: PassportType, code: string): void => {
    const passport = allPassports.find((p) => p.code === code && p.type === type);
    const compareElement: CompareElement = {
      selectedGlobalCode: passport && passport.globalCode,
      type,
    };
    updateCompareElement(compareElement, index);
  };

  useEffect(() => {
    compareElements.forEach((compareElement) => {
      const globalCode = compareElement.selectedGlobalCode;
      const selectedDocument = globalCode
        ? allPassports.find((d) => d.globalCode === globalCode)
        : null;
      if (selectedDocument && !selectedDocument.loaded) {
        fetch(`/assets/json/passports/${globalCode}.json`)
          .then((res) => res.json())
          .then(
            (result: WebPassport) => {
              updateExtraInfo(globalCode, result);
            },
            (error) => {
              // eslint-disable-next-line no-console
              console.error(error);
            }
          );
      }
    });
  }, [compareElements, allPassports]);

  useEffect(() => {
    if (window.location.search) {
      const allCodes = window.location.search.substring(9); // Remove ?compare=
      let urlCompareElements = allCodes.split('|').map((code) => {
        const ucCode = code.toUpperCase();
        const passport = allPassports.find((p) => p.globalCode === ucCode);
        const compareCode: CompareElement = {
          selectedGlobalCode: passport.globalCode,
          type: passport.type,
        };
        return compareCode;
      });
      while (urlCompareElements.length < 2) {
        urlCompareElements = [...urlCompareElements, {selectedGlobalCode: null, type: 'ORDINARY'}];
      }
      setCompareElements(urlCompareElements);
    }
  }, []);

  return (
    <Layout>
      <SEO title="Compare" pathname="/compare" />
      <h1 className="font-bold text-gray-500 mt-3 mb-2 max-w-7xl mx-auto">Compare</h1>

      <div className="max-w-7xl mx-auto flex mb-2">
        <div className="mr-3">
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 border-blue-700 text-white 
            border font-bold py-2 px-4 rounded my-2"
            onClick={() => addDocument()}
          >
            Add Document
          </button>
        </div>

        <div className="flex mr-3">
          <button
            type="button"
            onClick={() => setShowAllDestinations(true)}
            disabled={showAllDestinations}
            className={`rounded rounded-r-none border hover:scale-110 focus:outline-none py-2 px-4 my-2 font-bold
            duration-200 ease-in-out transition bg-blue-500 border-blue-700 text-white
            ${
              !showAllDestinations
                ? 'hover:bg-blue-700'
                : 'bg-blue-500 opacity-50 cursor-not-allowed'
            }`}
          >
            All Destinations
          </button>
          <button
            type="button"
            onClick={() => setShowAllDestinations(false)}
            disabled={!showAllDestinations}
            className={`rounded rounded-l-none border hover:scale-110 focus:outline-none py-2 px-4 my-2 font-bold
            duration-200 ease-in-out transition bg-blue-500 border-blue-700 text-white
            ${
              showAllDestinations
                ? 'hover:bg-blue-700'
                : 'bg-blue-500 opacity-50 cursor-not-allowed'
            }`}
          >
            Difference
          </button>
        </div>

        <div className="mr-3">
          {showMap ? (
            <>
              <ReactTooltip place="bottom" type="dark" effect="solid" />
              <button
                type="button"
                className="rounded rounded-r-none 
                bg-blue-500 hover:bg-blue-700 border-blue-700 text-white 
                border font-bold py-2 px-4 my-2"
                onClick={() => setShowMap(false)}
              >
                Hide Map
              </button>
              <button
                type="button"
                onClick={() => setMapModeInclusive(true)}
                disabled={mapModeInclusive}
                className={`border hover:scale-110 focus:outline-none py-2 px-4 my-2 
                font-bold duration-200 ease-in-out transition bg-gray-500 border-gray-700 text-white
                ${
                  !mapModeInclusive
                    ? 'hover:bg-gray-700'
                    : 'bg-gray-500 opacity-50 cursor-not-allowed'
                }`}
              >
                <span data-tip data-for="inclusiveTable">
                  Inclusive
                </span>
                <ReactTooltip id="inclusiveTable" place="top" type="dark" effect="solid">
                  <span>
                    Combined travel freedom for the selected documents. You should use this if you
                    have all these documents and want to see your travel freedom.
                  </span>
                </ReactTooltip>
              </button>
              <button
                type="button"
                onClick={() => setMapModeInclusive(false)}
                disabled={!mapModeInclusive}
                className={`rounded rounded-l-none border hover:scale-110 focus:outline-none py-2 px-4 my-2 
                font-bold duration-200 ease-in-out transition bg-gray-500 border-gray-700 text-white
                ${
                  mapModeInclusive
                    ? 'hover:bg-gray-700'
                    : 'bg-gray-500 opacity-50 cursor-not-allowed'
                }`}
              >
                <span data-tip data-for="exclusiveTable">
                  Exclusive
                </span>
                <ReactTooltip id="exclusiveTable" place="top" type="dark" effect="solid">
                  <span>
                    Common travel freedom for the selected documents. You should use this to see
                    where people with different documents can go together.
                  </span>
                </ReactTooltip>
              </button>
            </>
          ) : (
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 border-blue-700 text-white 
            border font-bold py-2 px-4 rounded m-2"
              onClick={() => setShowMap(true)}
            >
              Show map
            </button>
          )}
        </div>
      </div>

      <div
        className={`${
          !copyAlertVisible && 'hidden'
        } max-w-7xl mx-auto bg-green-400 text-white p-4 my-2 rounded`}
      >
        Copied!
      </div>

      <div className="mt-1 relative rounded-md shadow-sm max-w-7xl mx-auto mb-2">
        <div
          className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          style={{height: '3rem'}}
        >
          <span className="text-gray-500">Share URL:</span>
        </div>
        <input
          type="text"
          className="block w-full pl-28 pr-12 border-gray-300 text-gray-500 rounded-md"
          style={{height: '3rem'}}
          placeholder="Load a document to create share URL"
          value={shareUrl}
          ref={shareUrlField}
          readOnly
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 border-blue-700 text-white 
            py-2 px-4 border border-transparent font-bold rounded rounded-l-none"
            style={{height: '3rem'}}
            onClick={(): void => copyUrl(shareUrlField.current)}
          >
            Copy
          </button>
        </div>
      </div>

      {showMap && (
        <div className="max-w-7xl mx-auto">
          <WorldMap worldMapData={buildMapDataFromCompareMap(rankingMapObject)} />
          <p className="world-map-legend">
            Legend:
            <br />
            <span style={{color: MAP_COLORS.VISAFREE}}>&#9632;</span> - countries/territories
            visitable without a visa, or you can obtain visa on arrival.
            <br />
            <span style={{color: MAP_COLORS.ETA}}>&#9632;</span> - countries/territories visitable
            with an online visa/registration.
            <br />
            <span style={{color: MAP_COLORS.VISAPRIOR}}>&#9632;</span> - countries/territories which
            require a visa prior to arrival.
            <br />
            <span style={{color: MAP_COLORS.BANNED}}>&#9632;</span> - countries/territories which
            you cannot visit.
          </p>
        </div>
      )}

      <table className="max-w-7xl mx-auto mb-2 w-full">
        <thead>
          <tr>
            <th />
            {uiCompareElementsWithStats.map((compareElement, idx) => (
              <th key={idx} className="align-top">
                <div className="">
                  <SelectCountry
                    passports={compareElement.documentSelection}
                    selectedPassport={compareElement.selectedDocument}
                    onSelect={(passport) => {
                      changeDocument(idx, compareElement.type, passport.code);
                    }}
                  />
                  <SelectPassportType
                    selectedType="ORDINARY"
                    onSelect={(type: PassportType): void =>
                      changeDocument(
                        idx,
                        type,
                        compareElement.selectedDocument && compareElement.selectedDocument.code
                      )
                    }
                  />
                  <div className="grid lg:grid-cols-2 p-2">
                    <div className="">
                      {compareElement.selectedDocument &&
                        smallImagesMap.has(compareElement.selectedDocument.globalCode) && (
                          <PassportImage
                            smallImage={smallImagesMap.get(
                              compareElement.selectedDocument.globalCode
                            )}
                            largeImage={largeImagesMap.get(
                              compareElement.selectedDocument.globalCode
                            )}
                            passport={compareElement.selectedDocument}
                            format="VERTICAL"
                          />
                        )}
                    </div>
                    <div>
                      {compareElement.hasStats && (
                        <>
                          <div>{compareElement.visafree} visa-free</div>
                          <div>{compareElement.eta} electronic visa</div>
                          <div>{compareElement.visaprior} visa required</div>
                          <div>{compareElement.banned} banned</div>
                          <Link
                            target="_blank"
                            to={Passport.hrefPath(compareElement.selectedDocument)}
                          >
                            {Passport.adjectiveName(compareElement.selectedDocument)}
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border divide-y divide-gray-200">
          {destinationsToShow.map((destination) => (
            <tr key={destination.code} className="divide-x divide-gray-200">
              <td className="bg-white py-2 px-4">
                <Link to={Destination.hrefPath(destination)}>
                  {destination.name} {getFlagEmoji(destination.code)}
                </Link>
              </td>
              {uiCompareElementsWithStats.map((compareElement, index) => (
                <td
                  key={index}
                  className={`py-2 px-4 text-white ${compareClass(
                    compareElement.selectedDocument,
                    destination
                  )}`}
                >
                  {compareText(compareElement.selectedDocument, destination)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Layout>
  );
};

export default ComparePage;
